<template>
  <div
    class="card card-custom bgi-no-repeat gutter-b card-stretch"
    style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-2.svg)"
  >
    <!--begin::Body-->
    <div class="card-body">
      <a
        href="#"
        class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
        >Formula by Education</a
      >
      <!-- <div class="font-weight-bold text-success mt-9 mb-5">2 parameters</div> -->
      <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
        manage formulas based on education
      </p>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget15"
};
</script>
