<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xl-4">
        <ListWidget14></ListWidget14>
      </div>
      <div class="col-xl-4">
        <ListWidget15></ListWidget15>
      </div>
      <div class="col-xl-4">
        <ListWidgetPapiKostick></ListWidgetPapiKostick>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListWidget14 from "@/view/content/widgets/list/formulabyage.vue";
import ListWidget15 from "@/view/content/widgets/list/formulabyeducation.vue";
import ListWidgetPapiKostick from "@/view/content/widgets/list/formulabypapikostick.vue";

export default {
  name: "Psychometric",
  components: {
    ListWidget14,
    ListWidget15,
    ListWidgetPapiKostick,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master", route: "/master" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
